<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-input-power"/>
      {{ $t('forms.transl26')}}
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CForm>
            <CRow>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl23')"
                  v-model.trim="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  :placeholder="$i18n.translate('forms.transl27')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl28')"
                  v-model.trim="$v.form.surname.$model"
                  :isValid="checkIfValid('surname')"
                  :placeholder="$i18n.translate('forms.transl29')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CInput
              maxlength="255"
              :label="$i18n.translate('forms.transl33')"
              v-model.trim="$v.form.phone.$model"
              :placeholder="$i18n.translate('forms.transl33')"
              :isValid="checkIfValid('phone')"
              :invalidFeedback="$i18n.translate('forms.transl30')"
            />

            <CInput
              :label="$i18n.translate('forms.transl34')"
              v-model.trim="$v.form.email.$model"
              plaintext
            />

            <CRow>
              <CCol md="6">
                <CInput
                  v-model.trim="$v.form.password.$model"
                  :label="$i18n.translate('forms.transl35')"
                  type="password"
                  :placeholder="$i18n.translate('forms.transl35')"
                  :invalidFeedback="$i18n.translate('forms.transl31')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  v-model.trim="$v.form.password2.$model"
                  :label="$i18n.translate('forms.transl36')"
                  type="password"
                  :placeholder="$i18n.translate('forms.transl36')"
                  :invalidFeedback="$i18n.translate('forms.transl32')"
                />
              </CCol>
            </CRow>
            <h4 class="py-3">{{ $i18n.translate('forms.transl37')}}</h4>
            <CRow>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl39')"
                  v-model.trim="$v.form.company_name.$model"
                  :isValid="checkIfValid('company_name')"
                  :placeholder="$i18n.translate('forms.transl39')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl42')"
                  v-model.trim="$v.form.company_nip.$model"
                  :isValid="checkIfValid('company_nip')"
                  :placeholder="$i18n.translate('forms.transl42')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl43')"
                  v-model.trim="$v.form.company_regon.$model"
                  :placeholder="$i18n.translate('forms.transl43')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl44')"
                  v-model.trim="$v.form.company_email.$model"
                  :isValid="checkIfValid('company_email')"
                  :placeholder="$i18n.translate('forms.transl44')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl45')"
                  v-model.trim="$v.form.company_phone.$model"
                  :isValid="checkIfValid('company_phone')"
                  :placeholder="$i18n.translate('forms.transl45')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  maxlength="12"
                  label="$i18n.translate('forms.transl46')"
                  v-model.trim="$v.form.company_postal_code.$model"
                  :isValid="checkIfValid('company_postal_code')"
                  :placeholder="$i18n.translate('forms.transl46')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl47')"
                  v-model.trim="$v.form.company_street.$model"
                  :isValid="checkIfValid('company_street')"
                  :placeholder="$i18n.translate('forms.transl47')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl48')"
                  v-model.trim="$v.form.company_city.$model"
                  :isValid="checkIfValid('company_city')"
                  :placeholder="$i18n.translate('forms.transl48')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl49')"
                  v-model.trim="$v.form.company_bank_account_pl.$model"
                  :placeholder="$i18n.translate('forms.transl49')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl50')"
                  v-model.trim="$v.form.company_bank_account_international.$model"
                  :placeholder="$i18n.translate('forms.transl50')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl112')"
                  v-model.trim="$v.form.company_bank_account_international_swift.$model"
                  :placeholder="$i18n.translate('forms.transl112')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl113')"
                  v-model.trim="$v.form.company_bank_account_pl_name.$model"
                  :placeholder="$i18n.translate('forms.transl113')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl114')"
                  v-model.trim="$v.form.company_bank_account_international_name.$model"
                  :placeholder="$i18n.translate('forms.transl114')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CSelect id="status"
                     :label="$i18n.translate('forms.transl96')"
                     :value.sync="$v.form.status.$model"
                     :options="statuses"
                     :isValid="checkIfValid('status')"
            >
            </CSelect>

            <CTextarea
              :label="$i18n.translate('forms.transl51')"
              :placeholder="$i18n.translate('forms.transl51')"
              rows="9"
              v-model.trim="$v.form.description.$model"
            />

            <CButton
              color="primary"
              :disabled="$v.form.$invalid"
              @click="submit"
            >
              {{ $i18n.translate('forms.transl52') }}
            </CButton>
            <!--            <CButton-->
            <!--              class="ml-1"-->
            <!--              color="success"-->
            <!--              :disabled="$v.form.$invalid"-->
            <!--              @click="validate"-->
            <!--            >-->
            <!--              Validate-->
            <!--            </CButton>-->
            <!--            <CButton-->
            <!--              class="ml-1"-->
            <!--              color="danger"-->
            <!--              :disabled="!isDirty"-->
            <!--              @click="reset"-->
            <!--            >-->
            <!--              Reset-->
            <!--            </CButton>-->
            <CButton
              class="ml-1"
              color="success"
              @click="goBack"
            >
              {{ $i18n.translate('forms.transl53') }}
            </CButton>
          </CForm>
          <hr class="my-5"/>
          <h4>
            {{ $i18n.translate('forms.transl54') }}
          </h4>
          <CButton color="success" @click="createRecord3()" class="mb-3 my-5">{{ $i18n.translate('forms.transl55') }}
            <font-awesome-icon icon="fas fa-plus"/>
          </CButton>
          <div class="flex-center position-ref full-height">
            <data-table-carrier-car
              :fetch-url="datatTableUrl3"
              :columns="['id', 'producer',  'registration_number']"
              :headers="{'id': 'ID','producer': $t('forms.transl60'),'registration_number': $t('forms.transl61')}"
              :routeName="routeAddName3"
            ></data-table-carrier-car>
          </div>


          <hr class="my-5"/>
          <h4>
            {{ $i18n.translate('forms.transl56') }}
          </h4>
          <CButton color="success" @click="createRecord2()" class="mb-3 my-5">{{ $i18n.translate('forms.transl57') }}
            <font-awesome-icon icon="fas fa-plus"/>
          </CButton>
          <div class="flex-center position-ref full-height">
            <data-table-carrier-driver
              :fetch-url="datatTableUrl2"
              :columns="['id', 'name', 'surname']"
              :headers="{'id': 'ID','name': $t('forms.transl58'),'surname': $t('forms.transl59')}"
              :routeName="routeAddName2"
            ></data-table-carrier-driver>
          </div>

          <br/>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import CKEditor from 'ckeditor4-vue';
import Swal from "sweetalert2";
import {validationMixin} from "vuelidate"
import {required, minLength, email, sameAs, helpers} from "vuelidate/lib/validators"
import DataTableCarrierCar from "../../components/DataTableCarrierCar";
import DataTableCarrierDriver from "../../components/DataTableCarrierDriver";

export default {
  components: {DataTableCarrierDriver, DataTableCarrierCar},
  data() {
    return {
      datatTableUrl2: '',
      routeAddName2: '',
      datatTableUrl3: '',
      routeAddName3: '',
      form: {
        name: null,
        surname: null,
        phone: null,
        email: null,
        status: null,
        description: null,
        password: null,
        password2: null,
        company_name: null,
        company_nip: null,
        company_regon: null,
        company_email: null,
        company_phone: null,
        company_postal_code: null,
        company_street: null,
        company_city: null,
        company_bank_account_international: null,
        company_bank_account_pl_name: null,
        company_bank_account_international_name: null,
        company_bank_account_international_swift: null,
        company_bank_account_pl: null,
      },
      statuses: [],
    }
  },
  computed: {
    formString() {
      return JSON.stringify(this.form, null, 4)
    },
    isValid() {
      return !this.$v.form.$invalid
    },
    isDirty() {
      return this.$v.form.$anyDirty
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      surname: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      phone: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      email: {
        maxLength: 255
      },
      company_name: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      company_nip: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      company_regon: {
        minLength: minLength(3),
        maxLength: 255
      },
      company_email: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      company_phone: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      company_postal_code: {
        required,
        minLength: minLength(3),
        maxLength: 12
      },
      company_street: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      company_city: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      company_bank_account_international: {
        minLength: minLength(3),
        maxLength: 255
      },
      company_bank_account_pl_name: {
        minLength: minLength(3),
        maxLength: 255
      },
      company_bank_account_international_name: {
        minLength: minLength(3),
        maxLength: 255
      },
      company_bank_account_international_swift: {
        minLength: minLength(3),
        maxLength: 255
      },
      company_bank_account_pl: {
        minLength: minLength(3),
        maxLength: 255
      },
      status: {},
      description: {},
      password: {
        // minLength: minLength(8),
        // maxLength: 255,
        // strongPass: helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)
      },
      password2: {
        // maxLength: 255,
        // sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    createRecord2() {
      console.log(this.$router.push({path: this.routeAddName2}));
      // this.$router.push({path: this.routeAddName + '/create'});
    },
    createRecord3() {
      console.log(this.$router.push({path: this.routeAddName3}));
      // this.$router.push({path: this.routeAddName + '/create'});
    },
    goBack() {
      this.$router.replace({path: '/carriers'})
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },
    store() {
      let self = this;
      axios.post(this.$apiAdress + '/api/carriers/' + self.$route.params.id + '?token=' + localStorage.getItem("api_token")+ '&s_lng=' +  self.$i18n.locale() ,
        {
          _method: 'PUT',
          name: self.form.name,
          surname: self.form.surname,
          phone: self.form.phone,
          status: self.form.status,
          description: self.form.description,
          password: self.form.password,
          password2: self.form.password2,
          company_name: self.form.company_name,
          company_nip: self.form.company_nip,
          company_regon: self.form.company_regon,
          company_email: self.form.company_email,
          company_phone: self.form.company_phone,
          company_postal_code: self.form.company_postal_code,
          company_street: self.form.company_street,
          company_city: self.form.company_city,
          company_bank_account_international: self.form.company_bank_account_international,
          company_bank_account_pl_name: self.form.company_bank_account_pl_name,
          company_bank_account_international_name: self.form.company_bank_account_international_name,
          company_bank_account_international_swift: self.form.company_bank_account_international_swift,
          company_bank_account_pl: self.form.company_bank_account_pl,
        })
        .then(function (response) {
          if (response.data.status === 'success') {
            Swal.fire(
              self.$i18n.translate('forms.transl13'),
              self.$i18n.translate('forms.transl62'),
              'success'
            ).then(function () {
              self.$router.push('/carriers');
            });
          } else {
            Swal.fire(
              self.$i18n.translate('forms.transl64'),
              response,
              'error'
            )
          }
        }).catch(function (error) {
        if (error.response.data.message != '') {
          let errorDetails = ""
          for (let key in error.response.data.errors) {
            errorDetails += `${error.response.data.errors[key]}<br/>`
          }
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            errorDetails,
            'error'
          )
          window.scrollTo({top: 0});
        } else {
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            error,
            'error'
          )
          self.$router.push({path: 'login'});
        }
      });
    },

    submit() {
      this.validate();
      if (!this.$v.form.$invalid) {
        this.store();
      }
    },

    validate() {
      this.$v.$touch()
    },

    reset() {
      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },

    getEmptyForm() {
      return {
        name: null,
        surname: null,
        phone: null,
        description: null,
        password: null,
        password2: null,
        status: null,
        company_name: null,
        company_nip: null,
        company_regon: null,
        company_email: null,
        company_phone: null,
        company_postal_code: null,
        company_street: null,
        company_city: null,
        company_bank_account_international: null,
        company_bank_account_pl_name: null,
        company_bank_account_international_name: null,
        company_bank_account_international_swift: null,
        company_bank_account_pl: null,
      }
    }
  },
  created: function () {
    let self = this;
    axios.get(this.$apiAdress + '/api/carriers/' + self.$route.params.id + '/edit?token=' + localStorage.getItem("api_token"))
      .then(function (response) {
        self.statuses = response.data.statuses;
        self.form.name = response.data.record.name;
        self.form.surname = response.data.record.surname;
        self.form.phone = response.data.record.phone;
        self.form.email = response.data.record.email;
        self.form.description = response.data.record.description;
        self.form.password = response.data.record.password;
        self.form.password2 = response.data.record.password2;
        self.form.status = response.data.record.status;
        self.form.company_name = response.data.record.company_name;
        self.form.company_nip = response.data.record.company_nip;
        self.form.company_regon = response.data.record.company_regon;
        self.form.company_email = response.data.record.company_email;
        self.form.company_phone = response.data.record.company_phone;
        self.form.company_postal_code = response.data.record.company_postal_code;
        self.form.company_street = response.data.record.company_street;
        self.form.company_city = response.data.record.company_city;
        self.form.company_bank_account_international = response.data.record.company_bank_account_international;
        self.form.company_bank_account_pl_name = response.data.record.company_bank_account_pl_name;
        self.form.company_bank_account_international_name = response.data.record.company_bank_account_international_name;
        self.form.company_bank_account_international_swift = response.data.record.company_bank_account_international_swift;
        self.form.company_bank_account_pl = response.data.record.company_bank_account_pl;
        self.validate();
      }).catch(function (error) {
      console.log(error);
      self.$router.push({path: '/login'});
    });

    this.datatTableUrl2 = Vue.prototype.$apiAdress + '/api/carrier-drivers/' + this.$route.params.id + '?token=' + localStorage.getItem("api_token");
    this.routeAddName2 = '/carriers/' + this.$route.params.id + '/edit/carrierr-drivers/create';

    this.datatTableUrl3 = Vue.prototype.$apiAdress + '/api/carrier-cars/' + this.$route.params.id + '?token=' + localStorage.getItem("api_token");
    this.routeAddName3 = '/carriers/' + this.$route.params.id + '/edit/carrierr-cars/create';
  }
}
</script>
